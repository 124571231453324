html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', 'PT Serif';
}

.skip-to-link {
  position: absolute;
  top: -1000px;
  left: -1000px;
  color: transparent;

  &:focus {
    top: 5px;
    left: 5px;
    color: inherit;
  }
}

.MuiDrawer-root {
  z-index: 1501 !important; // zIndex.tooltip(1500) + 1. `!important` to override MUI inline styles
}

.MuiPopover-root {
  z-index: 1502 !important; // zIndex.tooltip(1500) + 2. `!important` to override MUI inline styles
}
